.pass-phrase {
    display: flex;
    justify-content: center;
    margin: 64px 0;
    font-size: 20px;
    flex-flow: column wrap;

    @media (min-width: 576px) {
        flex-flow: initial;
    }

    &__label {
        padding-right: 12px;
    }

    &__input {
        border: 0;
        border-radius: 0;
        border-bottom: solid 1px var(--color);
        color: var(--color);
        font-size: 20px;
        padding-bottom: 12px;

        @media (min-width: 576px) {
            padding-bottom: 0;
        }
        
        &:focus {
            outline: none;
        }
    }
}

.header {
    display: flex;
    padding: 16px 0;

    &__logo {
        margin-top: 6px;
        margin-bottom: -8px;

        img {
            width: 72px;
        }

        @media  (min-width: 576px) {
            margin-top: 8px;

            img {
                width: 96px;
            }    
        }
    }

    &__title-area {
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;

        &__title {
            font-size: 44px;

            @media (min-width: 576px) {
                font-size: 64px;
            }
        }
        
        &__sub-title {
            font-size: 16px;
            color: var(--second-color);
        }
    }
}
  
.divider {
    height: 1px;
    background-color: var(--border-color);
    margin: 2px 0;
}
  
.sections {
    padding: 16px 0;
}
  
.section-header {
    padding: 8px 0;
}
  
.images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 32px;
    padding-bottom: 32px;
}
  
.image {
    margin-bottom: auto;
    padding: 16px 16px 64px;
    box-shadow: 0 4px 6px var(--border-color);
    filter: grayscale(10%);
    transition: all 0.4s;
  
    &:hover {
        filter: none;
        transition: all 0.4s;
    }

    &--wide {
        @media (min-width: 576px) {
            grid-column-start: span 2;
        }
    }

    img {
        height: auto;
        max-width: 100%;
    }
}
  
.reset {
    height: 32px;
    margin-top: -32px;
}

.footer {
    a {
        color: var(--color);
    }
}