:root {
  --color: #131313;
  --second-color: #606060;
  --border-color: #b2b2b2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
}

.content {
  margin: auto;
  padding: 16px;
  background-color: white;
  max-width: 1200px;
}

@import 'components/components.scss'
